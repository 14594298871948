import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import 'lib-flexible/flexible';
import '@/assets/style/common.styl';
import 'animate.css';
import VueClipboard from 'vue-clipboard2';

import './icons'; // icon
import VueI18n from 'vue-i18n';

import scroll from 'vue-seamless-scroll';
Vue.use(scroll);

Vue.use(VueI18n);

let lang = window.localStorage.getItem('lang') || 'ja';
//语言包
const i18n = new VueI18n({
  locale: lang, // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    zh: require('@/assets/languages/chs.json'),
    ja: require('@/assets/languages/ja.json'),
  },
});

const change = (lang) => {
  if (lang == 'zh') {
    window.localStorage.setItem('lang', 'zh');
    i18n.locale = 'zh';
  } else{
    window.localStorage.setItem('lang', 'ja');
    i18n.locale = 'ja';
  } 
  Vue.prototype.$lang = lang;
};
//在任何地方都可以用这个方法进行语言切换
Vue.prototype.change = change;
Vue.prototype.$lang = lang;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
import {
  Empty,
  Step,
  Steps,
  Calendar,
  RadioGroup,
  Radio,
  Area,
  Search,
  TreeSelect,
  ImagePreview,
  Panel,
  Picker,
  Lazyload,
  Checkbox,
  CheckboxGroup,
  List,
  DropdownMenu,
  DropdownItem,
  Image,
  Popup,
  PullRefresh,
  Tag,
  Tab,
  Tabs,
  NoticeBar,
  Button,
  Field,
  Cell,
  CellGroup,
  Toast,
  NavBar,
  Tabbar,
  TabbarItem,
  Loading,
  Sticky,
  Row,
  Col,
  Icon,
  Grid,
  GridItem,
  Skeleton,
  ActionSheet,
  Switch,
  NumberKeyboard,
  DatetimePicker,
  Swipe,
  SwipeItem,
  PasswordInput,
  Notify,
  Slider,
  Divider,
  Uploader,
  Dialog,
  SubmitBar,
  CountDown,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
} from 'vant';
Vue.use(Empty)
  .use(Step)
  .use(Steps)
  .use(Calendar)
  .use(RadioGroup)
  .use(Radio)
  .use(ImagePreview)
  .use(Area)
  .use(Search)
  .use(TreeSelect)
  .use(Picker)
  .use(Uploader)
  .use(Lazyload)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Swipe)
  .use(SwipeItem)
  .use(Button)
  .use(Field)
  .use(Cell)
  .use(CellGroup)
  .use(Toast)
  .use(NavBar)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Loading)
  .use(Sticky)
  .use(Row)
  .use(Col)
  .use(Icon)
  .use(Grid)
  .use(GridItem)
  .use(NoticeBar)
  .use(Tab)
  .use(Tabs)
  .use(Tag)
  .use(PullRefresh)
  .use(Popup)
  .use(Image)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(List)
  .use(Skeleton)
  .use(ActionSheet)
  .use(Switch)
  .use(NumberKeyboard)
  .use(DatetimePicker)
  .use(PasswordInput)
  .use(Notify)
  .use(Slider)
  .use(Divider)
  .use(Dialog)
  .use(SubmitBar)
  .use(Panel)
  .use(CountDown)
  .use(GoodsAction)
  .use(GoodsActionIcon)
  .use(GoodsActionButton);
Toast.setDefaultOptions;
import validate from '@/utils/validate.js';
Vue.prototype.$validate = validate;
Vue.use(VueClipboard);

import func from '@/utils/func.js';
Vue.prototype.$func = func;

import VueJsonp from 'vue-jsonp';
Vue.use(VueJsonp);
// 全局权限检查
import './permission';
//远程资源域名
Vue.prototype.$imageUrl = 'https://cdn.aeetoscfd.co';
//Vue.prototype.$imageUrl = 'http://192.168.1.20:3333';
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
